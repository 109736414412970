<template>
	<div class="FM_page-home">
		<apexchart width="500" type="line" :options="options" :series="series" v-if="false"></apexchart>
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-4">
					<h1 class="FM_logo-home"><img src="~@/assets/logo/logo-v.png" class="img-logo" width="100%" alt="FOMO FUND"></h1>
					<div class="FM_descBox">
						<p class="FM_desc-home">
							Welcome to FOMO All In, Brave One; where you can invest in current blockchain opportunities that will take you to the moon! We relentlessly monitor the crypto metaverse and collect ideas from our inner circles, so that our investors will never miss out on the next big bang.
							<br><br>
							In FOMO we trust!  We’re All Gonna Make It (WAGMI)!
						</p>
					</div>
					<router-link to="/account/login" class="btn btn-block FM_btn btn-success font-weight-bold" v-if="!this.Login.isLogin">I’m a Believer</router-link>
					<router-link to="/invest/list" class="btn btn-block FM_btn btn-success font-weight-bold" v-if="this.Login.isLogin">I’m a Believer</router-link>
				</div>
				<div class="col-12 col-lg-8 align-self-center">
					<div class="FM_homeVideoBox">
						<img src="~@/assets/bg/home.gif" class="img-bg" width="100%">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { mapState, mapActions, mapMutations } from 'vuex'
import VueCookies from 'vue-cookies'

export default {
	name: 'Home',
	data() {
		return {
			options: {
				chart: {
					id: 'vuechart-example',
					background: '#000000',
					foreColor: 'pink'
				},
				grid: {
					borderColor: 'blue',
				},
				// colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
				fill: {
					colors: 'pink',
					opacity: 0.9,
					type: 'solid',
					image: {
						src: [],
						width: undefined,
						height: undefined
					},
					pattern: {
						style: 'verticalLines',
						width: 6,
						height: 6,
						strokeWidth: 2,
					},
				},
				xaxis: {
					categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: undefined,
					formatter: function (val, opts) {
						return val
					},
					textAnchor: 'middle',
					distributed: false,
					offsetX: 0,
					offsetY: 0,
					style: {
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
						colors: undefined
					},
					background: {
						enabled: true,
						foreColor: 'red',
						padding: 4,
						borderRadius: 2,
						borderWidth: 1,
						borderColor: 'blue',
						opacity: 0.9,
						dropShadow: {
							enabled: false,
							top: 1,
							left: 1,
							blur: 1,
							color: 'pink',
							opacity: 0.45
						}
					},
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45
					}
				}
			},
			series: [{
				name: 'series-1',
				data: [30, 40, 45, 50, 49, 60, 70, 91]
			}]
		}
	},
	computed: {
		...mapState(['Login'])
	},
	created() {
		this.checkLogin();
	},
	methods: {
		...mapActions(['getStatus']),
		checkLogin() {
			this.getStatus().then(result => {
				if (!result.data.login) {
					VueCookies.remove('account');
					VueCookies.remove('service-token');
				}
			});
		}
	}
}
</script>
